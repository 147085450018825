import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { Box, SxProps } from "@mui/material";
import { memo, useMemo } from "react";
import { ICustomDayProps } from "@/interfaces/AvailabilityCalendar";

export type DateInfo = { [key: string]: { isNextDayReserved?: boolean; reservationId: string; bookingDateType?: 'first' | 'last' | 'mid' | 'bb' } }


const triangleLeft = {
  width: 0,
  height: 0,
  zIndex: '1 !important',
  borderTop: '0px solid transparent',
  borderBottom: '36px solid transparent',
  borderLeft: '56px solid #272d24'
}
const triangleRight = {
  width: 0,
  height: 0,
  zIndex: '1 !important',
  borderRight: '0px solid transparent',
  borderLeft: '56px solid transparent',
  borderBottom: '36px solid #272d24'
}

const CustomDay = (props: PickersDayProps<Dayjs> & ICustomDayProps & { currentActiveMonth: Dayjs; reservationInfo: DateInfo }) => {
  const { id, curDate, onClickDate, dateList, day, reservationInfo, outsideCurrentMonth, currentActiveMonth, ...other } = props;

  const date = day.get("date");
  const clickedDate = curDate ? day.isSame(curDate, "date") : false;

  const memoized = useMemo(() => {
    const isCurrentPresent = dateList.includes(date);
    const ExccedFlag = day.add(1, "days").get("date") === 1;
    const isPreviousDatePresent = dateList.includes(date - 1);
    const isNextDatePresent = ExccedFlag ? false : dateList.includes(date + 1);
    const yesterday = day.subtract(1, "days");
    const tomorrow = day.add(1, "days");
    const isPrevClicked = curDate ? yesterday.isSame(curDate, "date") : false;
    const isNextClicked = curDate ? tomorrow.isSame(curDate, "date") : false;
    const isNextToday = ExccedFlag ? false : dayjs().isSame(tomorrow, "date");
    const isPrevToday = dayjs().isSame(yesterday, "date");

    const doesWeekStarts = day.isSame(day.startOf("week"), "date");
    const doesWeekEnds = day.isSame(day.endOf("week"), "date");

    const isToday = dayjs().isSame(day, "date") && currentActiveMonth && day.isSame(currentActiveMonth, "month");
    const isYesterday = dayjs().isSame(tomorrow, "date");
    const isTomorrow = dayjs().isSame(yesterday, "date");

    const isLastConsecutiveDate = isCurrentPresent && !isNextDatePresent;
    const isFirstConsecutiveDate = isCurrentPresent && !isPreviousDatePresent;


    return {
      isCurrentPresent,
      isPreviousDatePresent,
      isNextDatePresent,
      isPrevClicked,
      isNextClicked,
      isNextToday,
      isPrevToday,

      doesWeekStarts,
      doesWeekEnds,

      isToday,
      isTomorrow,
      isYesterday,

      isLastConsecutiveDate,
      isFirstConsecutiveDate
    };
  }, [curDate, date, dateList, day, currentActiveMonth]);



  const {
    isCurrentPresent,
    isToday,
  } = memoized;


  const { isBackToBack, isLastDayOfBooking, isFirstDayOfBooking } = useMemo(() => {
    const isBackToBack = reservationInfo[date]?.bookingDateType === 'bb'
    const isFirstDayOfBooking = reservationInfo[date]?.bookingDateType === 'first'
    const isLastDayOfBooking = reservationInfo[date]?.bookingDateType === 'last'
    const isConsecutivelyBooked = reservationInfo[date]?.bookingDateType === 'last' && reservationInfo[date + 1]?.bookingDateType === 'first'
    return {
      isBackToBack,
      isLastDayOfBooking,
      isFirstDayOfBooking,
      isConsecutivelyBooked
    }
  }, [date, reservationInfo])

  const triangle = useMemo(() => {
    if (!outsideCurrentMonth) {
      if (isFirstDayOfBooking) {
        return triangleLeft
      }
      if (isLastDayOfBooking) {
        return triangleRight
      }
    }
    return {}
  }, [isFirstDayOfBooking, isLastDayOfBooking, outsideCurrentMonth])



  return (
    <Box width='54px'>
      <Box position='relative' display='flex' sx={{ background: isCurrentPresent && !outsideCurrentMonth ? "#206A14" : undefined, zIndex: 1, }} justifyContent='center'>
        <Box sx={triangle} position='absolute' />
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
          disableRipple
          disableTouchRipple
          disableMargin
          style={{
            color: "white",
            zIndex: 1,
          }}
          sx={{
            borderTopRightRadius: '0%',
            borderBottomRightRadius: '0%',
            borderTopLeftRadius: '0%',
            borderBottomLeftRadius: '0%',
          }}
          onClick={() => onClickDate(day, isCurrentPresent, date)}
        />
        {isToday && (
          <Box
            position="absolute"
            bottom={0}
            left={12}
            width="32px"
            height="4px"
            bgcolor="#F27B25"
            borderRadius="4px"
            zIndex={1}
          />
        )}
        {isBackToBack && !outsideCurrentMonth && (
          <Box position='absolute' sx={{ background: '#1C2218', zIndex: 0, height: '100%', width: '6px', transform: 'skew(-50deg)' }} />
        )}
        <Box id='cornerBoxLeft' />
      </Box>
    </Box>
  );
};

export default memo(CustomDay);