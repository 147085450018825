

import { SxProps } from "@mui/material"
import { DateCalendarProps } from "@mui/x-date-pickers"

const calendarSX: DateCalendarProps<any>["sx"] = {
  "& *": {
    boxSizing: "border-box",
  },
  "&.MuiDateCalendar-root": {
    minHeight: "356px",
    maxHeight: "max-content",
    width: "min-content",
    background: "#272D24",
  },
  "& .MuiPickersCalendarHeader-root": {
    display: "flex",
    justifyContent: "center",
    columnGap: "40px",
    margin: 0,
    padding: 0,
    marginBottom: "37px",
    ".MuiPickersCalendarHeader-labelContainer": {
      margin: "unset",
      order: 2,
    },
    ".MuiPickersArrowSwitcher-root": {
      display: "contents",
    },
    ".MuiPickersArrowSwitcher-spacer": {
      display: "none",
    },
  },
  "& .MuiPickersSlideTransition-root": {
    minHeight: "300px",
  },
  "& .MuiDayCalendar-monthContainer": {
    position: "static",
  },
  "& .MuiDayCalendar-weekContainer, & .MuiDayCalendar-header, & .MuiDayCalendarSkeleton-week": {
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    margin: 0,
    marginBottom: "4px",
  },
  "& .MuiDayCalendar-header": {
    marginBottom: "2px",
    display: 'flex',
    gap: '10px',
    ".MuiDayCalendar-weekDayLabel": {
      width: "44px",
      height: "35px",
      margin: 0,
    },
  },
  "& .MuiDayCalendar-weekContainer .BookedDate:first-of-type": {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
  "& .MuiDayCalendar-weekContainer .BookedDate:last-child": {
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
  },
  "& .MuiPickersDay-root": {
    paddingRight: "0px",
    paddingLeft: "0px",
    width: "54px",
    height: "35px",
    "&.Mui-selected": {
      color: "inherit",
      backgroundColor: "transparent",
    },
    
  },
  "& .MuiDayCalendarSkeleton-root": {
    width: "100%",
    ".MuiSkeleton-root": {
      width: "55px !important",
      height: "35px !important",
      margin: 0,
    },
  },
}

const headerSX: SxProps = {
  "&": { position: "relative" },
  "& .MuiPickersArrowSwitcher-root": {
    width: 0,
  },
  "& .MuiPickersCalendarHeader-labelContainer": {
    margin: "auto",
  },
  "& .MuiIconButton-edgeEnd": {
    order: 1,
    padding: 0,
  },
  "& .MuiIconButton-edgeStart": {
    order: 3,
    padding: 0,
  },
}

export { calendarSX, headerSX }

